const usingRollbar = import.meta.env.MODE !== 'development' && import.meta.env.MODE !== 'test';
if (usingRollbar && window.Rollbar == null) {
	console.error('Attempted to use Rollbar but window.Rollbar not present');
}

function log(method: string, ...args) {
	if (usingRollbar) {
		window.Rollbar?.[method](...args);
	}
	console[method](...args);
}

export function logError(...args) {
	log('error', ...args);
}

export function logWarn(...args) {
	log('warn', ...args);
}

export function logInfo(...args) {
	log('info', ...args);
}

export function logDebug(...args) {
	log('debug', ...args);
}
